.taplabs-loader {
}

.taplabs-loader > svg {
  height: 120px;
}

.taplabs-loader .loader-circle {
  -webkit-animation: circle-in 1s cubic-bezier(0, 0, 0.1, 1) infinite,
    circle-out 1s cubic-bezier(0.6, 0, 1, 1) infinite;
  animation: circle-in 1s cubic-bezier(0, 0, 0.1, 1) infinite,
    circle-out 1s cubic-bezier(0.6, 0, 1, 1) infinite;
}

.taplabs-loader .loader-drop {
  stroke-dasharray: 0 1000;
  -webkit-animation: drop-move 1s cubic-bezier(0, 0.6, 1, 0.4) infinite,
    drop-scale 1s ease-in-out infinite;
  animation: drop-move 1s cubic-bezier(0, 0.6, 1, 0.4) infinite, drop-scale 1s ease-in-out infinite;
}

@-webkit-keyframes circle-in {
  0% {
    stroke-dasharray: 0 1000;
  }
  66% {
    stroke-dasharray: 33 1000;
  }
  100% {
    stroke-dasharray: 33 1000;
  }
}

@keyframes circle-in {
  0% {
    stroke-dasharray: 0 1000;
  }
  66% {
    stroke-dasharray: 33 1000;
  }
  100% {
    stroke-dasharray: 33 1000;
  }
}
@-webkit-keyframes circle-out {
  0% {
    stroke-dashoffset: 0;
  }
  33% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -33;
  }
}
@keyframes circle-out {
  0% {
    stroke-dashoffset: 0;
  }
  33% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -33;
  }
}
@-webkit-keyframes drop-move {
  0% {
    opacity: 0;
  }
  12.99% {
    opacity: 0;
  }
  13% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  97% {
    stroke-dashoffset: -44;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: -44;
    opacity: 1;
  }
}
@keyframes drop-move {
  0% {
    opacity: 0;
  }
  12.99% {
    opacity: 0;
  }
  13% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  97% {
    stroke-dashoffset: -44;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: -44;
    opacity: 1;
  }
}
@-webkit-keyframes drop-scale {
  0% {
    stroke-width: 1.2;
  }
  10% {
    stroke-width: 1.2;
  }
  55% {
    stroke-width: 3;
  }
  97% {
    stroke-width: 2;
  }
}
@keyframes drop-scale {
  0% {
    stroke-width: 1.2;
  }
  10% {
    stroke-width: 1.2;
  }
  55% {
    stroke-width: 3;
  }
  97% {
    stroke-width: 2;
  }
}
