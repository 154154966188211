.notification {
  flex-direction: column;
  height: auto !important;
  max-width: 420px;
}

.notification-title {
  line-height: 24px;
}

.notification-message {
  line-height: 22px;
  font-weight: 400;
}

.notification-action-wrapper {
  margin-left: auto !important;
}

.notification-badge {
  width: 7px;
  height: 7px;
  background: var(--theme-color);
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 12px;
  left: 22px;
}
