*,
html,
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

:root {
  --theme-color: #ed1b24;
  --theme-dark-color: #dd3333;
  --theme-dark-text: #ae1e1e;
  --theme--hover-color: rgba(237, 27, 36, 0.12);
  --theme-highlight-color: rgba(237, 27, 36, 0.07);
  --sub-color: #777;
  --text-color: #333333;
  --border-color: rgb(231, 231, 231);
  --success-background: rgb(221, 252, 230);
  --success-text: rgb(32, 124, 65);
  --secondary-color: rgb(51, 51, 51);
}

a {
  color: var(--theme-color);
}

a:hover,
a:focus {
  color: var(--theme-dark-text);
}

.navbar-default .navbar-nav > li > a:not(.btn):focus,
.navbar-default .navbar-nav > li > a:not(.btn):hover {
  color: var(--theme-color);
}

.rdtPicker td.rdtToday {
  background-color: var(--theme-highlight-color);
}

button.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.25s ease-in-out;
}

button.btn > svg {
  flex-shrink: 0;
}

button.btn-round {
  border-radius: 50% !important;
}

.round {
  border-radius: 50%;
}

.navbar .navbar-minimize {
  margin: 0 12px;
}

.navbar-minimize .btn-round {
  width: 32px;
  height: 32px;
}

.navbar-collapse {
  margin-left: auto;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
}

.switch {
  display: flex;
  flex-direction: column;
}

.switch .form-group .control-label {
  margin: 0;
}

.switch .form-group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btn-danger {
  color: var(--theme-dark-text);
  font-weight: 500;
  border-color: var(--theme-dark-text);
  border-width: 1px;
  padding: 7px 15px;
}

.btn-danger:hover {
  border-color: var(--theme-color);
  color: var(--theme-color);
  background-color: var(--theme--hover-color);
}

.btn-danger.btn-fill {
  background-color: var(--theme-dark-color);
  color: white;
}

.btn-danger.btn-fill:hover {
  color: #ffffff;
  background-color: #c33e2a;
}

.grid {
  display: grid;
}

.grid-2col {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-3col {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-2col .col-2span {
  grid-column: 1 / span 2;
}

.grid-2col .col-3span {
  grid-column: 1 / span 2;
}

.grid-3col .col-3span {
  grid-column: 1 / span 3;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex.items-center,
.grid.items-center {
  align-items: center;
}

.flex.items-end,
.grid.items-end {
  align-items: flex-end;
}

.flex.items-start,
.grid.items-start {
  align-items: flex-start;
}

.flex.justify-center {
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-6 {
  width: 24px;
}

.h-6 {
  height: 24px;
}

.w-5 {
  width: 22px;
}

.h-5 {
  height: 22px;
}

.w-4 {
  width: 18px;
}

.h-4 {
  height: 18px;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.sub-text {
  color: var(--sub-color);
}

.main-text {
  color: var(--text-color);
}

.theme-text {
  color: var(--theme-dark-color);
}

.success-text {
  color: var(--success-text);
}

.text-bold {
  font-weight: 700;
}

.text-regular {
  font-weight: 400;
}

.text-light {
  font-weight: 300;
}

.text-semibold {
  font-weight: 600;
}

.text-xxs {
  font-size: 12px;
}

.text-xs {
  font-size: 13px;
}

.text-normal {
  font-size: 14px;
}

.text-xl {
  font-size: 16px;
}

.text-xxl {
  font-size: 18px;
}

.text-nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.tap-sidebar {
  background: #fff;
  overflow-y: auto;
  border-right: 1px solid var(--border-color);
}

.tap-sidebar::before,
.tap-sidebar::after {
  display: none;
}

.tap-sidebar .sidebar-links {
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 0px !important;
}

.tap-sidebar .sidebar-wrapper {
  width: 100%;
  overflow: hidden;
  box-shadow: none;
  height: auto;
  padding-bottom: 24px;
}

.tap-sidebar .nav a p {
  font-weight: 600;
  font-size: 12px;
}

.tap-sidebar .nav a svg {
  flex-shrink: 0;
}

.tap-sidebar .nav li > a {
  margin: 0;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.tap-sidebar .nav.collapse li > a {
  padding: 6px 0px;
}

.tap-sidebar .nav a p {
  flex-grow: 1;
}

.tap-sidebar .nav a p,
.tap-sidebar .nav a svg,
.tap-sidebar .nav a i,
.tap-sidebar .nav.collapse a > span {
  color: var(--text-color);
}

.tap-sidebar .nav li.active > a p,
.tap-sidebar .nav li.active > a svg,
.tap-sidebar .nav li.active > a i {
  color: var(--theme-color);
}

.tap-sidebar .nav li:hover > a {
  background: #f4f4f4;
}

.tap-sidebar .nav li.active > a {
  background: var(--theme--hover-color);
  color: var(--theme-color);
}

.tap-sidebar .nav p .caret {
  margin-left: auto;
}

.sidebar-mini .tap-sidebar .nav a svg {
  margin-left: 4px;
}

.sidebar-mini .tap-sidebar .nav li.active > a p {
  opacity: 0;
  width: 0;
}

.taplabs-sidebar-mini-icon {
  display: none;
}

.sidebar-mini .taplabs-sidebar-mini-icon {
  display: block;
}

.sidebar-mini .taplabs-sidebar-large-icon {
  display: none;
}

.card {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border: 1px solid var(--border-color);
}

.card .header {
  padding: 10px 16px 0px 16px;
}

.card .title {
  font-weight: 500;
  font-size: 18px;
}

.separator {
  background: var(--border-color);
}

.separator.horizontal {
  height: 1px;
}

.separator.vertical {
  width: 1px;
  height: 100%;
}

.flex .separator.vertical {
  height: auto;
  align-self: stretch;
}

.nav > li.open > .dropdown-menu,
.dropdown.open .dropdown-menu,
.dropup.open .dropdown-menu,
.bootstrap-table .open .dropdown-menu {
  margin-top: 12px;
}

.nav > li > .dropdown-menu {
  border-radius: 6px;
  border-color: var(--border-color);
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.nav > li > .dropdown-menu:after,
.nav > li > .dropdown-menu:before {
  display: none;
}

.badge {
  font-weight: 600;
  padding: 5px 12px;
  font-size: 11px;
}

.badge.success {
  background-color: var(--success-background);
  color: var(--success-text);
}

.badge.error {
  background-color: var(--theme--hover-color);
  color: var(--theme-color);
}

.badge.primary {
  background-color: #e5effa;
  color: #0169dc;
}

.badge.warning {
  background-color: #fdf0d8;
  color: #e08a00;
}

.badge.info {
  background-color: #f2f2f2;
  color: #636363;
}

/* Tables */
.ReactTable .rt-noData {
  text-align: center;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.07) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.ReactTable .pagination-bottom .-center {
  display: none;
}

.ReactTable .pagination-bottom {
  padding-top: 10px;
  margin-left: auto;
}

.ReactTable .pagination-bottom .-pagination {
  gap: 0px;
}

.ReactTable .pagination-bottom .-pagination .-btn {
  padding: 6px 10px 2px 10px;
}

.ReactTable .pagination-bottom .-next button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ReactTable .pagination-bottom .-previous button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ReactTable .rt-resizer {
  width: 16px;
}

.ReactTable .rt-thead .rt-th:last-child {
  text-align: left;
}

.ReactTable .rt-thead .rt-th {
  font-weight: 600;
  font-size: 12px;
  text-align: left;
}

.ReactTable .rt-thead.-header {
  border-bottom: 1px solid var(--border-color);
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid var(--border-color);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 1px solid var(--border-color);
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  color: var(--text-color);
}

.ReactTable.-striped .rt-tr.-odd {
  background-color: rgb(249, 250, 251);
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  font-size: 13px;
  font-weight: 400;
  padding: 8px 6px;
  text-overflow: ellipsis;
}

.pagination-btn {
  padding: 6px 12px;
}

.pagination-btn.left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-btn.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-calendar-timeline {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--border-color);
}

.react-calendar-timeline .rct-sidebar {
  border-right: 1px solid var(--border-color) !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid var(--border-color) !important;
  padding: 0 10px !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: rgba(0, 0, 0, 0.03) !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: var(--theme-highlight-color) !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid var(--border-color) !important;
}

.react-calendar-timeline .rct-calendar-header {
  border: none !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid var(--border-color) !important;
}

.react-calendar-timeline .rct-item-content {
  height: 100% !important;
  top: 0;
  left: 0;
  position: absolute !important;
  width: 100% !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  line-height: 14px !important;
  padding: 0 4px !important;
  flex-grow: 1;
}

.card .category,
.card .form-group > label {
  color: var(--text-color);
  font-weight: 600;
  font-size: 12px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  width: 90%;
  max-width: 460px;
  min-width: 300px;
}

.modal-backdrop {
  background-color: rgba(107, 114, 128, 0.75);
}

.modal-dialog .modal-content,
.swal2-modal,
.sweet-alert,
.mapboxgl-popup .mapboxgl-popup-content {
  border-color: var(--border-color);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.swal2-popup.swal2-modal {
  width: 48em;
  max-width: 90%;
}

.swal2-popup .swal2-title {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 22px;
}

.swal2-popup .swal2-content {
  line-height: 22px;
}

.swal2-popup .swal2-actions {
  margin-top: 32px;
}

.sweet-alert h2 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 0;
  color: var(--text-color);
}

.sweet-alert p {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-top: 16px;
}

.sweet-alert p .btn {
  font-size: 16px;
  font-weight: 600;
}

.sweet-alert .text-muted {
  padding: 0 4%;
  margin-top: 24px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--sub-color);
}

.modal-body .card .content {
  padding-bottom: 15px !important;
}

.modal-title {
  font-weight: 600;
  font-size: 22px;
}

.modal-dialog .card {
  margin-bottom: 0;
}

.main-content .card {
  margin-bottom: 0;
}

.card-calendar .rbc-toolbar .rbc-toolbar-label {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.card-calendar .rbc-time-view {
  border-bottom: none;
}

.rbc-btn-group {
  order: 2;
}

.card-calendar .rbc-calendar {
  margin: -1px;
}

.card-calendar .rbc-toolbar button {
  padding: 4px 12px;
}

.card-calendar .rbc-day-slot .rbc-event-content {
  margin-top: 4px;
  line-height: 15px;
}

.card-calendar .rbc-day-slot .rbc-event-label {
  font-size: 9px;
}

.card .rbc-day-slot .rbc-event-content {
  font-size: 11px;
  line-height: 13px;
}

.card-calendar .rbc-day-slot .rbc-event.rbc-event-blocked,
.appointments-calendar .rbc-day-slot .rbc-event.rbc-event-blocked,
.card-calendar .rbc-event-blocked.rbc-selected,
.appointments-calendar .rbc-event-blocked.rbc-selected {
  opacity: 0.9;
  border-color: var(--text-color);
  background-color: var(--text-color);
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4),
    var(--text-color) 1px,
    var(--text-color) 0,
    var(--text-color) 50%
  );
}

.appointments-calendar .rbc-event.rbc-event-blocked,
.card-calendar .rbc-day-slot .rbc-event.rbc-event-blocked {
  color: white;
}

.card-calendar:not(.appointments-calendar) .rbc-day-slot .rbc-event.rbc-event-blocked {
  left: 20% !important;
  width: 80% !important;
}

.card-calendar .rbc-header {
  text-align: center;
}

.card-calendar .rbc-header a {
  font-weight: 400;
  color: var(--sub-color);
  font-size: 13px;
}

.card-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 10px 12px;
}

.card-calendar .rbc-selected {
  color: white;
}

.rbc-today {
  background: var(--theme-highlight-color);
}

.card-calendar .rbc-time-header {
  margin-right: 0px;
}

.appointments-calendar .rbc-toolbar {
  padding: 10px;
  margin: 0;
}

.appointments-calendar .rbc-toolbar .rbc-toolbar-label {
  font-size: 15px !important;
}

.appointments-calendar .rbc-time-header.rbc-overflowing {
  margin-right: 0px !important;
  padding: 0px;
}

.appointments-calendar .rbc-header {
  padding: 6px;
}

.appointments-calendar .rbc-day-slot .rbc-event {
  display: flex;
  border-radius: 3px;
}

.appointments-calendar .rbc-day-slot .rbc-event {
  border-color: transparent;
}

.appointments-calendar .rbc-event.rbc-selected {
  background: var(--theme-dark-color);
}

.appointments-calendar .rbc-event.rbc-event-red {
  background: rgba(174, 30, 30, 0.85);
  color: white;
}

.appointments-calendar .rbc-day-slot .rbc-event-content {
  margin-top: 2px;
}

.appointments-calendar .rbc-allday-cell {
  display: none;
  visibility: hidden;
}

.appointments-calendar .rbc-time-column .rbc-time-slot {
  color: var(--sub-color);
  font-size: 12px;
  font-weight: 500;
}

.appointments-calendar .rbc-time-content {
  border-top-width: 1px;
}

.pdf-preview-container .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-preview-container .react-pdf__Page {
  max-width: 100%;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  margin: 1em;
}

.pdf-preview-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.ql-container {
  font-size: 16px !important;
  min-height: 300px !important;
  max-height: 700px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
}

.ql-editor {
  max-height: 680px !important;
  overflow: auto !important;
}

.ql-container .ql-editor p,
.ql-container ul,
.ql-container ol,
.ql-container dl {
  margin-bottom: 1rem !important;
  margin-top: 0;
}

.ql-container .ql-editor h1,
.ql-container .ql-editor h2,
.ql-container .ql-editor h3,
.ql-container .ql-editor h4,
.ql-container .ql-editor h5 {
  margin-bottom: 1rem !important;
  font-weight: bold !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Normal (16px)";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "20px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
  font-size: 20px !important;
}

@media (min-width: 991px) and (max-width: 1280px) {
  .rbc-calendar .rbc-toolbar {
    flex-direction: row;
  }
}

div.google-visualization-tooltip {
  padding: 6px 12px;
}

.react-time-picker__inputGroup {
  display: flex;
  align-items: baseline;
}

.form-group .react-time-picker {
  display: flex;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.spinner-border {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: -0.125em;
  border: 0.16em solid;
  border-right: 0.16em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  flex-grow: 0 !important;
  flex-shrink: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.error-alert {
  background-color: var(--theme--hover-color);
  border: 1px solid var(--theme-color);
  padding: 1.4rem;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: var(--theme-dark-text);
  border-radius: 4px;
  overflow: hidden;
  white-space: pre-line;
  word-break: normal;
}

.login-page .card .header {
  padding-bottom: 16px;
}

.login-page > .content,
.lock-page > .content {
  padding-top: 15vh;
}

.react-select
  .react-select__control
  .react-select__indicators
  .react-select__clear-indicator:before {
  top: 0;
}

#service-color-popover > .arrow:after {
  display: none;
}

#service-color-popover .popover-content {
  padding: 0;
}

.service-color-preview {
  width: 36px;
  height: 18px;
  border-radius: 4px;
}

.appointment-colors-legend {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 10px;
  column-gap: 32px;
  margin-top: 12px;
}

.appointment-colors-legend .appointment-legend-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
}

.appointment-colors-legend .appointment-legend-color.blocked-slots {
  background-color: var(--sub-color);
  background-size: 6px 6px;
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4),
    var(--text-color) 1px,
    var(--text-color) 0,
    var(--text-color) 50%
  );
}

.appointment-colors-legend .appointment-color-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}

.services-grid {
  display: grid;
  gap: 13px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}

.service-card {
  display: flex;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 7px;
  padding: 10px;
  align-items: center;
  background: #fff;
  gap: 12px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.service-folder.service-card {
  background: #fdfdfd;
}

.service-card img {
  width: 48px;
  height: 48px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

.service-folder.service-card img {
  width: 72px;
  height: 72px;
}

.service-card .service-title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.service-card .service-price {
  font-weight: 400;
  color: var(--theme-color);
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.service-card button {
  border-color: transparent;
}

.services-grid .service-drag-container[aria-pressed="true"] {
  z-index: 10;
}

.service-folder-content {
  transition: all 0.3s ease-in-out;
}

.service-folder-content.closed {
  min-height: 0;
  height: 0;
  overflow: hidden;
}

.service-folder-content.open {
  min-height: auto;
}

.services-grid {
  padding-bottom: 24px;
}

@media (min-width: 768px) {
  .modal-dialog {
    margin-top: 10%;
    width: 95%;
    max-width: 640px;
  }

  .wide.modal-dialog {
    width: 95%;
    max-width: 90%;
    max-width: 900px;
  }
}

@media (max-width: 768px) {
  .grid-2col {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .grid-3col {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .grid-2col .col-2span {
    grid-column: 1 / span 1;
  }
}

/* .rbc-event-blocked {
width: 100% !important;
}
.rbc-event-content {
  width: 100% !important;
}
.appointments-calendar .rbc-event.rbc-event-red {
  left: 0 !important;
  width: 100% !important;
} */